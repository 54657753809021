




















































































































































































































































































































































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator"
import Loading from "@/components/Loading.vue"
import { RenderedBlock, Block } from "@/models"
import { BlockFragment, BlockUpdateFragment } from "../fragments"
import Fields from "@/components/form/Fields.vue"
import PreviewParams from "@/components/tools/PreviewParams.vue"
import _isEqual from "lodash/isEqual"
import _cloneDeep from "lodash/cloneDeep"
import gql from "graphql-tag"
import cleanData from "@/utils/gql/cleanData"
import { ApolloError } from "apollo-client"
import { alert, confirmDelete, prompt } from "@/components/dialogs"
import ComponentSelect from "@/components/fields/componentSelect/Field.vue"
import CollectionFieldSelect from "@/components/fields/collectionFieldSelect/Field.vue"
import SingleSelect from "@/components/fields/select/Field.vue"
import ContentField from '@/components/fields/content/Field.vue'
import ComponentEditorDialog from '@/components/ComponentEditorDialog.vue'
import * as monaco from 'monaco-editor'
import MonacoEditor, { editorEnv } from '@/plugins/monaco'

@Component({
  components: {
    Loading,
    Fields,
    PreviewParams,
    ComponentSelect,
    CollectionFieldSelect,
    SingleSelect,
    MonacoEditor,
    ComponentEditorDialog,
    ContentField
  },
  apollo: {
    savedBlock: {
      query: gql`
        query getBlock($blockId: ID) {
          savedBlock: block(blockId: $blockId) {
            ...Block
          }
        }
        ${BlockFragment}
      `,
      variables() {
        return {
          blockId: this.componentId,
        }
      },
    },
    preview: {
      query: gql`
        query getContent($blockId: ID, $preview: Boolean, $params: JSON) {
          preview: renderBlock(
            blockId: $blockId
            preview: $preview
            params: $params
          )
        }
      `,
      variables() {
        return {
          blockId: this.componentId,
          params: JSON.parse(this.previewParamsStr),
          preview: true,
        }
      },
      error(e: ApolloError, instance: BlockEditorEdit) {
        this.previewError = e.message
      },
    },
  },
})
export default class BlockEditorEdit extends Vue {
  @Prop({ type: String, required: true }) environmentId!: string
  @Prop({ type: String, required: true }) componentId!: string
  activeTab = null

  preview: Readonly<RenderedBlock> | null = null

  saving = false
  previewParams: any[] = []
  previewError = ""
  codeEditorOptions = {
    automaticLayout: true
  }

  savedBlock: Readonly<Block> | null = null
  block: Partial<Block> = {}

  inlineEditComponentType = ''
  inlineEditComponentId = ''
  inlineEditComponentOpen = false

  @Watch("savedBlock")
  update(newData: Block) {
    this.$set(this, "block", _cloneDeep(this.savedBlock))
    if (this.savedBlock && this.savedBlock.previewParams) {
      const pvParams = JSON.parse(this.savedBlock.previewParams)
      this.$set(
        this,
        "previewParams",
        Object.keys(pvParams).map((k: string) => ({
          name: k,
          type: typeof pvParams[k] as "string" | "number" | "boolean",
          value: pvParams[k],
        }))
      )
    } else {
      this.previewParams = []
    }
  }

  @Watch("previewParams")
  updateParams(newParams: any[]) {
    if (!this.block) return
    this.block.previewParams = this.previewParamsStr
  }

  // Clean data for dirty method
  getSavedBlockClean(): Partial<Block> | null {
    let saved: Partial<Block> | null = this.savedBlock
    if (saved?.previewParams === null) {
      saved.previewParams = "{}"
    }
    if (saved?.content === "") {
      saved.content = null
    }
    return saved
  }

  get dirty() {
    return !_isEqual(this.block, this.getSavedBlockClean())
  }

  get previewParamsStr() {
    const params: Record<string, any> = {}
    this.previewParams.forEach((param) => {
      params[param.name] = param.value
    })
    return JSON.stringify(params)
  }

  get previewContent() {
    return this.preview && this.preview.content
  }

  normalize() {
    if (!this.block.collectionId) {
      this.block.filterable = false
    }
    if (!this.block.filterable) {
      this.block.filtersIds = []
    }
    if (!this.block.filtersIds) {
      this.block.allowsNoFilter = true
      this.block.filterByDefault = ""
    }
    if (
      !this.block.allowsNoFilter &&
      this.block.filtersIds &&
      this.block.filtersIds.length <= 1
    ) {
      this.block.filterByDefault = this.block.filtersIds[0]
    }
  }

  async save () {
    if (!this.block || this.saving) return
    this.saving = true
    try {
      this.normalize()
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation($blockId: ID, $block: UpdateBlockInput) {
            updateBlock(blockId: $blockId, block: $block) {
              ...Block
            }
          }
          ${BlockFragment}
        `,
        // Parameters
        variables: {
          blockId: this.componentId,
          block: cleanData(this.block, BlockUpdateFragment),
        },
      })

      await this.$apollo.queries.preview.refetch()

      this.$emit('updated')
      this.$emit("save", result.data.updateBlock)
    } catch (e) {
      this.$emit("error", e)
      console.error(e)
    } finally {
      this.saving = false
    }
  }

  async rename () {
    const newName = await prompt('Introduce un nuevo nombre para el bloque', { defaultValue: this.block.name })
    if (newName !== false) {
      this.block.name = newName as string
      await this.save()
    }
  }

  async duplicate () {
    const cloneName = await prompt([
      'Se duplicará:',
      ' - Este bloque, su contenido y todas sus opciones',
      'NO se duplicará:',
      ' - ID o nombres del bloque',
      ' - Cualquier componente (filtros, colecciones, etc) al que este bloque haga referencia.',
      '---',
      'Introduce un nombre para el nuevo bloque:'
    ].join('\n'), { defaultValue: this.block.name + ' (Copia)' })
    if (cloneName !== false) {
      try {
        this.saving = true
        const { data } = await this.$apollo.mutate({
          mutation: gql`mutation duplicateBlock ($blockId : ID, $newName : String) {
            result: duplicateBlock (blockId: $blockId, newName: $newName) {
              _id
            }
          }`,
          variables: {
            blockId: this.componentId,
            newName: cloneName
          }
        })
        const { _id:newId } = data.result
        this.$emit('updated')
        this.$router.push({
          name: 'adminBlockEdit',
          params: {
            environmentId: this.environmentId,
            componentId: newId
          }
        })
      } catch (e) {
        console.error(e)
        this.$emit("error", e)
      } finally {
        this.saving = false
      }
    }
  }

  async deleteItem() {
    if (
      !(await confirmDelete(
        "¿Seguro que quieres eliminar por completo este bloque de contenido?"
      ))
    )
      return
    if (!this.block || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation($blockId: ID) {
            deleteBlock(blockId: $blockId)
          }
        `,
        // Parameters
        variables: {
          blockId: this.componentId,
        },
      })
      this.$emit('updated')
      this.$emit("delete", result.data.deleteBlock)
      this.$router.push({
        name: 'adminBlockIndex',
        params: {
          environmentId: this.environmentId
        }
      })
    } catch (e) {
      this.$emit("error", e)
      console.error(e)
    }
  }

  editorDidMount(editor : monaco.editor.IStandaloneCodeEditor) {
    editorEnv.environmentId = this.environmentId
    // Options
    const model = editor.getModel()
    model?.updateOptions({
      tabSize: 2,
      insertSpaces: true
    })
    
    // Actions
    editor.addAction({
      id: 'save',
      label: 'Guardar Cambios',
      keybindings: [
        monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_S
      ],
      run: () => this.save()
    })
    // Events
    editorEnv.onEditComponent = (componentType, componentId) => {
      this.inlineEditComponentOpen = true
      this.inlineEditComponentType = componentType
      this.inlineEditComponentId = componentId
    }
  }
}
