









































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import gql from 'graphql-tag'
import FuzzySearch from 'fuzzy-search'
import { Block, PaginatedQueryResult } from '@/models'

@Component({
  apollo: {
    blocks: {
      query () {
        return gql`
          query getBlocks ($environmentId: ID) {
            blocks (environmentId: $environmentId) {
              items {
                _id
                name
                title
                type
              }
            }
          }
        `
      },
      variables () {
        return {
          environmentId: this.environmentId
        }
      },
      fetchPolicy: 'network-only'
    }
  }
})
export default class BlockEditorSidebar extends Vue {
  @Prop({ type: String, required: true }) environmentId !: string
  @Prop({ type: Number }) lastUpdated ?: number

  blocks : Partial<PaginatedQueryResult<Block>> = {}
  searchQuery = ''

  get blockResults () {
    if (!this.blocks.items) return []
    const displayResult = this.blocks.items.map((block) => ({
      ...block,
      name: block.name,
      title: block.title,
      subtitle: block.title || {
        html: 'Contenido HTML',
        text: 'Texto Enriquecido'
      }[block.type!],
      icon: {
        html: 'polymer',
        text: 'line_style'
      }[block.type!]
    }))
    const searcher = new FuzzySearch(displayResult || [], ['name', 'title', 'subtitle', '_id'])
    return searcher.search(this.searchQuery)
  }

  @Watch('lastUpdated')
  refreshBlockList () {
    this.$apollo.queries.blocks.refresh()
  }
}
