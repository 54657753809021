
































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class BlockEditorHome extends Vue {
  @Prop({ type: Boolean }) isDrawerOpen ?: boolean
}
